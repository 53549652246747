  .form-container {
    flex: 1;
    padding: 10% 10% 20% 10%  ;
    background-color: #fff;
    border-radius: 5px;
    align-items: center;
    min-height: 80vh;
  }
  
  .dropdown-open {
    pointer-events: none;
  }

  h1 {
    text-align: center;
    color: #333;
  }
  form {
    margin-top: 40px;
  }
  label {
    display: block;
    margin-bottom: 10px;
    color: #777;
  }
  input[type="text"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-bottom: 20px;
  }
  input[type="checkbox"] {
    margin-bottom: 20px;
  }
  input[type="submit"] {
    width: 100%;
    padding: 12px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  input[type="submit"]:hover {
    background-color: #0056b3;
  }