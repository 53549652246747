footer {
    background-color: #f2f2f2;
    padding: 2% 10% 2% 10%;
    bottom: 0;
    width: 100%;
    min-height: 10vh
  }
  
  .nav-list {
    list-style-type: none;
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .nav-item {
    margin: 0;
  }
  
  .nav-link {
    text-decoration: none;
    color: #333;
    font-weight: bold;
  }
  
  .footer-text {
    text-align: center;
    margin-top: 10px;
    color: #666;
  }
  